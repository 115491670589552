<template>
  <div>
    <vs-row vs-justify="center">
      <!-- Voltar para lista -->
      <vs-col
        vs-lg="12"
        vs-xs="12"
        vs-sm="12"
        class="mb-3"
      >
        <vs-button
          color="primary"
          class="mt-4"
          @click="goBackToInvitationList"
        >
          <i class="fa fa-undo-alt" /> Voltar para lista
        </vs-button>
      </vs-col>

      <!-- Header -->
      <vs-col
        vs-lg="12"
        vs-xs="12"
        vs-sm="12"
      >
        <vs-card class="">
          <div>
            <div class="d-flex justify-content-between  no-block align-items-start">
              <h2 class="mb-3">
                {{ votingData.votationInf.votationName }}
              </h2>
              <vs-button
                class="btn btn-secondary text-light"
                icon="sync"
                @click="getVotingData"
              >
                Atualizar dados
              </vs-button>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <h3 class="mb-0">
                  {{ votingData.votationInf.votationInitDate + ' ' + votingData.votationInf.votationInitTime }}
                </h3>
                <h6 class="font-weight-light text-muted">
                  Início da votação
                </h6>
              </div>
              <div>
                <h3 class="mb-0">
                  {{ votingData.votationInf.votationEndDate + ' ' + votingData.votationInf.votationEndTime }}
                </h3>
                <h6 class="font-weight-light text-muted">
                  Fim da votação
                </h6>
              </div>
              <div>
                <h3 class="mb-0">
                  {{ votingData.totalConsidered | formatLocale }}
                </h3>
                <h6 class="font-weight-light text-muted">
                  Total de votos
                </h6>
              </div>
              <div>
                <h3 class="mb-0">
                  {{ votingData.concludedPerc | formatLocale }}%
                </h3>
                <h6 class="font-weight-light text-muted">
                  Porcentagem de conclusão
                </h6>
              </div>
            </div>
          </div>
        </vs-card>
      </vs-col>

      <!-- Resultado Geral -->
      <vs-col
        :key="key"
        vs-justify="center"
        vs-lg="12"
        vs-xs="12"
        vs-sm="12"
      >
        <vs-row>
          <vs-col
            vs-justify="center"
            vs-lg="12"
            vs-xs="12"
            vs-sm="12"
          >
            <vs-card
              v-for="(voting, votingInd) in resultGeneral"
              :key="votingInd"
            >
              <h2 class="mb-5">
                {{ voting.title }}
              </h2>
              <vs-row class="mb-5">
                <vs-col
                  vs-xs="12"
                  vs-sm="12"
                  vs-lg="12"
                  style="overflow-x: hidden"
                >
                  <swiper :options="swiperOption">
                    <swiper-slide
                      v-for="(item, index) in voting.values"
                      :key="index"
                      class="slide"
                    >
                      <h2>
                        <span style="font-size: 2.2em">{{ index+1 }}º</span>
                      </h2>
                      <h2 class="my-4">
                        {{ item.text }}
                      </h2>
                      <div>
                        <h3 class="mb-0">
                          {{ item.value }}
                        </h3>
                        <h6 class="mb-4 font-weight-light text-muted">
                          Total de votos
                        </h6>
                      </div>
                      <div>
                        <h3 class="mb-0 mt-1">
                          {{ item.perc }}%
                        </h3>
                        <h6 class="mb-4 font-weight-light text-muted">
                          Total de votos
                        </h6>
                      </div>
                      <a
                        v-if="item.imgUrl"
                        :href="item.imgUrl"
                        target="_blank"
                      >
                        <img
                          style="max-width: 100%"
                          :src="item.imgUrl"
                          :alt="`Imagem de ${ item.text}`"
                        >
                      </a>
                    </swiper-slide>
                    <div
                      slot="pagination"
                      class="swiper-pagination"
                    />
                  </swiper>
                </vs-col>
              </vs-row>
            </vs-card>
          </vs-col>
        </vs-row>
      </vs-col>

      <vs-col
        vs-lg="12"
        vs-xs="12"
        vs-sm="12"
        class="mb-3"
      >
        <vs-button
          color="primary"
          class="mt-4"
          @click="goBackToInvitationList"
        >
          <i class="fa fa-undo-alt" /> Voltar para lista
        </vs-button>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
/* Components */
import {swiper, swiperSlide} from 'vue-awesome-swiper';

/* Services */
import Voting from '@/services/voting';
/* Style */
import 'swiper/dist/css/swiper.min.css';

const voting = new Voting();

export default {
  name: 'ViewDetails',
  components: {
    swiper,
    swiperSlide
  },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      votingData: {},
      resultGeneral: [],
      resultMasc: [],
      resultFem: [],
      resultX: [],
      key: true,
    }
  },
  computed: {
    swiperOption() {
      return {
        slidesPerView: 4,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 40
          }
        }
      }
    },
  },
  beforeMount() {
    this.getVotingData()
  },
  methods: {
    goBackToInvitationList() {
      this.$store.dispatch('set_isLoadingActive', false);
      this.$router.push({path: '/votacoes'});
    },
    async getVotingData() {
      this.$store.dispatch('set_isLoadingActive', true)
      const resp = await voting.getVotingStats(this.code)
      this.votingData = resp
      let array = []
      resp.result.forEach((item) => {
        if (Number(item.type) === 9) {
          array.push(item)
        }
      })
      this.resultGeneral = array
      this.key = !this.key
      this.$store.dispatch('set_isLoadingActive', false)
    }
  }
}
</script>

<style scoped>
</style>
